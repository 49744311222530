<template>
    <div>
        <el-container>
            <el-header>
                <el-row>
                    <el-col :span="2">备注</el-col>
                    <el-col :span="2">
                        <el-input v-model="params.remark" size="mini"></el-input>
                    </el-col>
                    <el-col :span="2">
                        <el-button @click="loaddata" size="mini">查询</el-button>
                    </el-col>
                </el-row>
            </el-header>
            <el-container>
            <el-table :data="rs" height="600">
                    <el-table-column prop="acc2" label="数据库" width="180px"></el-table-column>
                    <el-table-column prop="type" label="是否停用" width="180px"></el-table-column>
                    <el-table-column prop="remark" label="备注"></el-table-column>
                </el-table>
           </el-container>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    export default {
        name: "account",
        extends: ax.control(),
        data() {
            return {
                params: {
                    remark: "",
                }
            }
        },
        mounted() {
            this.loaddata();
        },
        methods: {
            loaddata() {
                let _this = this;
                ax.base.post("/syc/getAccount", _this.params).then((data) => {
                    _this.rs = data.data
                    console.log(data);
                }).catch((data) => {
                    console.log(data);
                })
            }
        }
    }
</script>
<style scoped>
    .el-header,
    .el-footer {
        background-color: #b3c0d1;
        color: #333;
        /* text-align: center; */
        line-height: 60px;
    }

    .el-main {
        background-color: #e9eef3;
        color: #333;
        /* text-align: center; */
        line-height: 600px;
    }

    body>.el-container {
        margin-bottom: 40px;
    }



    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }
</style>